import React from 'react';
import {Collapse, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import List from "@material-ui/core/List";
import {makeStyles} from "@material-ui/core/styles";
import BSMResultIcon from "./BSMResultIcon";
import BorderColorRoundedIcon from '@material-ui/icons/BorderColorRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import {red} from "@material-ui/core/colors";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(1),
    },
}));

export default function BSMListItem(props) {

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };


    return (
        <>
            <ListItem button onClick={handleClick} className={classes.root}>
                {/*<ListItemText primary={props.item.msrDate}/>*/}
                <ListItemText primary={props.item.msrTypeString}/>
                <ListItemText primary={props.item.msrVal + ' mg/dL'}/>
                <BSMResultIcon msrVal={props.item.msrVal} msrType={props.item.msrType} />
                {open ? <ExpandLess/> : <ExpandMore/>}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem button className={classes.nested}>
                        <ListItemIcon onClick={()=>props.editData(props.item)} style={{ minWidth: '40px'}}>
                            <BorderColorRoundedIcon/>
                        </ListItemIcon>
                        <ListItemText primary={props.item.msrMemo}/>
                        <ListItemIcon onClick={()=>props.deleteData(props.item)} style={{ minWidth: '30px'}}>
                            <DeleteRoundedIcon style={{color: red[500]}}/>
                        </ListItemIcon>
                    </ListItem>
                </List>
            </Collapse>
        </>
    );
}