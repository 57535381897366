import React from 'react';
import {ListItem, ListItemText, ListSubheader} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import {red} from "@material-ui/core/colors";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '20px',
        width: '100%',
        // maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    }
}));

export default function WnPList(props) {

    const classes = useStyles();
    const wnpData = props.wnpData;

    const WGT = (val) => {

        if (val===0) {
            return <ListItemText/>
        }else {
            return <ListItemText primary={val + ' kg'}/>
        }
    }

    const PRS = (val, val2) => {

        if (val===0) {
            return <ListItemText/>
        }else {
            return <ListItemText primary={val + '/' + val2 + ' mmHg'}/>
        }
    }



    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                    체중, 혈압 측정 이력
                </ListSubheader>
            }
            className={classes.root}
        >
            {
                wnpData && wnpData.content && wnpData.content.map((item, idx) => {
                    return (
                        <ListItem button className={classes.root} onClick={()=>props.editData(item)} key={idx}>
                            <Grid container>
                                <Grid item xs={4}>
                                    <ListItemText primary={item.msrDate}/>
                                </Grid>
                                <Grid item xs={3} style={{ paddingLeft: '20px'}}>
                                    {WGT(item.wgtVal)}
                                </Grid>
                                <Grid item xs={4}>
                                    {PRS(item.prsHigh, item.prsLow)}
                                </Grid>
                                <Grid item xs={1} onClick={()=>props.deleteData(item)} >
                                    <DeleteRoundedIcon style={{color: red[500], paddingTop: '5px'}}/>
                                </Grid>
                            </Grid>
                        </ListItem>
                    );
                })
            }
        </List>
    );
}