import axios from 'axios';

const instance = axios.create();

class APIUtil {

    handleModal = ()=> {};

    constructor(handleModal) {

        this.handleModal = handleModal;

        instance.interceptors.request.use(
            function (config) {

                handleModal(true);
                return config;

            }, function (error) {

                handleModal(false);
                return Promise.reject(error);
            }
        );

        instance.interceptors.response.use(
            function (response) {

                handleModal(false);
                return Promise.resolve(response);
            },
            function (error) {
                console.log(error);

                // 인증 오류시 로그인 페이지 이동 처리
                if (error.response.status === 401 || error.response.status === 403) {

                    window.location.href='/login';
                }

                handleModal(false);

                return Promise.reject(error);
            }
        );
    }



    /**
     * 인증 여부 체크
     *
     * @returns {Promise<string>}
     */
    checkLogin = ()=> {

        return instance.get('/api/checkLogin');
    }

    login = (id, pass) => {

        const loginForm = new FormData();
        loginForm.append('userId', id);
        loginForm.append('userPass', pass);

        instance({
            method: 'post',
            url: '/api/login',
            data: loginForm,
            headers: { "Content-Type": "multipart/form-data" }
        }).then(response=>{
            // 메인페이지로 이동
            window.location.href='/';
        }).catch(response=> {
            console.log('로그인 오류 ', response);
        });
    }

    logout = ()=> {

        instance.get("/api/logout").then(response=>{
            window.location.href='/login';
        }).catch(response=> {
            console.log('로그아웃 오류 ', response);
            window.location.href='/login';
        });
    }

    saveBSM = (inputData) => {

        return instance({
            method: 'post',
            url: '/api/bsm',
            data: inputData
        });
    };

    getBSMList = (cond) => {

        return instance.get('/api/bsm', {
            params: cond
        });
    };

    deleteBSMData = (bsmInfo) => {

        return instance.delete('/api/bsm/' + bsmInfo.id);
    };

    saveWnP = (inputData) => {

        return instance({
            method: 'post',
            url: '/api/wgt_prs',
            data: inputData
        });
    };

    getWnPList = (cond) => {

        return instance.get('/api/wgt_prs', {
            params: cond
        });
    };

    deleteWnPData = (wnpInfo) => {

        return instance.delete('/api/wgt_prs/' + wnpInfo.id);
    };

    getBSMListByType = (cond) => {

        return instance.get('/api/bsm/stats/val', {
            params: cond
        });
    }

}

export default APIUtil;