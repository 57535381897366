import React, {useEffect, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import ApiUtil from "./api/ApiUtil";
import {CircularProgress, Modal} from "@material-ui/core";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://sblood.cafe24.com/">
                SBlood 혈당관리
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function SignIn() {
    const classes = useStyles();

    const [userId, setUserId] = useState('');
    const [userPass, setUserPass] = useState('');
    const [autoSave, setAutoSave] = useState(false);
    const [open, setOpen] = useState(false);

    const apiUtil = new ApiUtil(setOpen);

    const handleLogin = ()=> {
        console.log('로그인');

        if (autoSave) {
            window.localStorage.setItem('userId', userId);
            window.localStorage.setItem('userPass', userPass);
            window.localStorage.setItem('autoSave', "true");
        } else {
            window.localStorage.removeItem('userId');
            window.localStorage.removeItem('userPass');
            window.localStorage.removeItem('autoSave');
        }

        apiUtil.login(userId, userPass);
    }
    const handleIdChange = (e)=> {
        setUserId(e.target.value);
    }
    const handlePassChange = (e) => {
        setUserPass(e.target.value);
    }
    const handleAutoSave = (e) => {
        setAutoSave(e.target.checked);
    };

    const handleClose = () => {
        setOpen(false);
    };


    useEffect(() => {

        let as = window.localStorage.getItem('autoSave');

        if (as==="true") {

            setUserId(window.localStorage.getItem('userId'));
            setUserPass(window.localStorage.getItem('userPass'));
            setAutoSave(true);
        }

    }, []);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    혈당관리 로그-인
                </Typography>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="userid"
                    label="아이디"
                    name="userid"
                    autoComplete="userid"
                    autoFocus
                    onKeyDown={handleIdChange}
                    onChange={handleIdChange}
                    value={userId}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={handlePassChange}
                    onKeyDown={handlePassChange}
                    value={userPass}
                />
                <FormControlLabel
                    control={<Checkbox value="remember" checked={autoSave} onClick={handleAutoSave} onChange={handleAutoSave} color="primary"/>}
                    label="로그인 정보 유지"
                />
                <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={handleLogin}
                >
                    로그인
                </Button>
                <Grid container>
                    <Grid item xs>
                        <Link href="#" variant="body2">
                            비밀번호 초기화
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link href="#" variant="body2">
                            {"회원가입"}
                        </Link>
                    </Grid>
                </Grid>
            </div>
            <Box mt={8}>
                <Copyright/>
            </Box>
            <Modal open={open} onClose={handleClose} disableEnforceFocus={true} disableAutoFocus={true}
                   style={{display:'flex',alignItems:'center',justifyContent:'center'}} >
                <div>
                    <CircularProgress color="secondary" />
                </div>
            </Modal>
        </Container>
    );
}