import React, {useEffect} from "react";
import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import ApiUtil from "../../api/ApiUtil";
import ModalSpinner from "../common/ModalSpinner";
import ko from "date-fns/locale/ko";
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import {MenuItem, Paper, Select} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";


export default function Stats() {

    const [data, setData] = React.useState([]);
    const [dataWgt, setDataWgt] = React.useState([]);
    const [spinnerOpen, setSpinnerOpen] = React.useState(false);
    const [fromDate, setFromDate] = React.useState(new moment(new Date()).subtract(2, 'w').format('YYYY-MM-DD').toString());
    const [toDate, setToDate] = React.useState(new moment(new Date()).format('YYYY-MM-DD').toString());
    const [msrType, setMsrType] = React.useState('00');

    // 최초 조회시 기본 값 Load
    const [cond, setCond] = React.useState({
        "msrType" : "00",
        "fromDate": new moment(new Date()).subtract(2, 'w').format('YYYY-MM-DD').toString(),
        "toDate": new moment(new Date()).format('YYYY-MM-DD').toString()
    });

    const setCondDate = (term) => {

        let fd = new moment(new Date(toDate));

        if (term === 0) fd = fd.subtract(2, 'w');
        if (term === 1) fd = fd.subtract(1, 'M');
        if (term === 2) fd = fd.subtract(3, 'M');

        setFromDate(fd.format('YYYY-MM-DD').toString());
        setCond({...cond, fromDate: fd.format('YYYY-MM-DD').toString()});
    }

    const handleFromDateChange = (date) => {
        const fromDate = new moment(date).format('YYYY-MM-DD').toString();
        setFromDate(fromDate);
        setCond({...cond, fromDate: fromDate});
    };

    const handleToDateChange = (date) => {
        const toDate = new moment(date).format('YYYY-MM-DD').toString();
        setToDate(toDate);
        setCond({...cond, toDate: toDate});
    };

    const handleMsrTypeChange = (event) => {
        console.log(event.target.value);
        setMsrType(event.target.value);
        setCond({...cond, msrType: event.target.value});
    };

    const apiUtil = new ApiUtil(setSpinnerOpen);

    const search = ()=> {
        apiUtil.getBSMListByType(cond)
            .then(result=> {
                result.data.map(item=> {

                    item.msrDate = item.msrDate.substring(5).replaceAll('-', '/');
                    return item;
                })
                setData(result.data);
            }).catch(err=> {
            console.log(err);
        });
    }

    const searchWeight = () => {

        let condition = {};
        condition.fromDate = fromDate;
        condition.toDate =  toDate;
        condition.sortOrder = 'desc';
        condition.page = 0;
        condition.size = 9999;

        apiUtil.getWnPList(condition)
            .then(response=> {

                console.log(response.data);
                response.data.content.map(item=> {

                    item.msrDate = item.msrDate.substring(5).replaceAll('-', '/');
                    return item;
                })
                setDataWgt(response.data.content);
            }).catch(err=> {
            console.log(err);
        });
    }

    useEffect(() => {
            search();
            searchWeight();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , []);

    useEffect(()=> {
        search();
        searchWeight();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cond])

    return (

        <Paper style={{
            marginTop: '20px',
            padding: '10px',
            paddingLeft: '20px',
            paddingRight: '20px',
            textAlign: 'center'
        }}>
            <Typography variant="h6" component="h6">
                혈당 통계
            </Typography>
            <Grid container>
                <MuiPickersUtilsProvider locale={ko} utils={DateFnsUtils}>
                    <Grid item xs={6}>
                        <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-from-date"
                            label="조회 시작일"
                            format="yyyy-MM-dd"
                            value={fromDate}
                            onChange={handleFromDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            style={{textAlign: 'center'}}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-to-date"
                            label="조회 종료일"
                            format="yyyy-MM-dd"
                            value={toDate}
                            onChange={handleToDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            style={{textAlign: 'center'}}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button variant="outlined" onClick={()=>setCondDate(0)}>2주</Button>
                            </Grid>
                            <Grid item>
                                <Button variant="outlined" onClick={()=>setCondDate(1)}>1개월</Button>
                            </Grid>
                            <Grid item>
                                <Button variant="outlined" onClick={()=>setCondDate(2)}>3개월</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Select
                            labelId="msr-type-select-helper-label"
                            id="msr-type-select-helper"
                            value={msrType}
                            onChange={handleMsrTypeChange}
                            variant="outlined"
                            style={{width: '100%', height: '36px'}}
                        >
                            <MenuItem value="00">조식전</MenuItem>
                            <MenuItem value="01">조식후</MenuItem>
                            <MenuItem value="10">중식전</MenuItem>
                            <MenuItem value="11">중식후</MenuItem>
                            <MenuItem value="20">석식전</MenuItem>
                            <MenuItem value="21">석식후</MenuItem>
                            <MenuItem value="30">취침전</MenuItem>
                        </Select>
                    </Grid>
                </MuiPickersUtilsProvider>
            </Grid>
            <ResponsiveContainer width="100%" height={150}>
                <LineChart
                    // width={800}
                    height={150}
                    data={data}
                    margin={{
                        top: 20,
                        // right: 30,
                        // left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="msrDate" style={{ fontSize: '0.8em'}} reversed={false} padding={{ left: 10, right: 10}}/>
                    <YAxis domain={['dataMin', 'dataMax']} style={{ fontSize: '0.7em'}} unit="mg/dL"/>
                    <Tooltip/>
                    {/*<Legend margin={{top: 10}}/>*/}
                    <Line type="monotone" dataKey="측정값" stroke="#8884d8" activeDot={{r: 8}}/>
                </LineChart>
            </ResponsiveContainer>
            <ResponsiveContainer width="100%" height={150}>
                <LineChart
                    // width={800}
                    height={150}
                    data={dataWgt}
                    margin={{
                        top: 20,
                        // right: 30,
                        // left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="msrDate" style={{ fontSize: '0.8em'}} reversed={true} padding={{ left: 10, right: 10}}/>
                    <YAxis unit="kg" domain={['dataMin', 'dataMax']} style={{ fontSize: '0.8em'}}/>
                    <Tooltip/>
                    {/*<Legend margin={{top: 10}}/>*/}
                    <Line type="monotone" dataKey="wgtVal" stroke="#82ca9d" activeDot={{r: 8}}/>
                </LineChart>
            </ResponsiveContainer>
            <ModalSpinner spinnerOpen={spinnerOpen}/>
        </Paper>
    );
};