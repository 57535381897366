import 'date-fns';
import React, {useEffect} from "react";
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import Grid from "@material-ui/core/Grid";
import ko from "date-fns/locale/ko";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputAdornment,
    MenuItem,
    Paper,
    Select,
    Snackbar,
    TextField
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import MuiAlert from '@material-ui/lab/Alert';
import ApiUtil from "../../api/ApiUtil";
import ModalSpinner from "../common/ModalSpinner";
import moment from 'moment';
import BSMList from "./BSMList";
import PropTypes from 'prop-types';
import {blue} from "@material-ui/core/colors";


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


function ConfirmationDialogRaw(props) {
    const { onClose, value: valueProp, open, ...other } = props;

    const handleCancel = () => {
        onClose('N');
    };

    const handleOk = () => {
        onClose('Y');
    };

    return (
        <Dialog
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={open}
            {...other}
        >
            <DialogTitle id="confirmation-dialog-title">삭제 확인</DialogTitle>
            <DialogContent dividers>
                <Typography>
                    측정 기록을 삭제할까요?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel} color="primary">
                    아니오
                </Button>
                <Button onClick={handleOk} color="primary">
                    예
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ConfirmationDialogRaw.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
};


/**
 * 혈당 측정 결과 입력 및 조회
 *
 * @constructor
 */
export default function BloodSugar() {

    let inputData = {};
    // 최초 조회시 기본 값 Load
    const [cond, setCond] = React.useState({
        "page": 0,
        "size": 9999,
        "fromDate": new moment(new Date()).subtract(3, 'M').format('YYYY-MM-DD').toString(),
        "toDate": new moment(new Date()).format('YYYY-MM-DD').toString(),
        "sortOrder": "desc"
    });

    const [open, setOpen] = React.useState(false);
    const [confirmOpen, setConfirmOpen] = React.useState(false);
    const [spinnerOpen, setSpinnerOpen] = React.useState(false);
    const [msrDate, setMsrDate] = React.useState(new Date());
    const [msrType, setMsrType] = React.useState('00');
    const [msrMemo, setMsrMemo] = React.useState('');
    const [msrValue, setMsrValue] = React.useState('');
    const [errMessage, setErrMessage] = React.useState('');
    const [delData, setDelData] = React.useState({});
    const [bsmData, setBsmData] = React.useState({});

    const [fromDate, setFromDate] = React.useState(new moment(new Date()).subtract(3, 'M').format('YYYY-MM-DD').toString());
    const [toDate, setToDate] = React.useState(new moment(new Date()).format('YYYY-MM-DD').toString());


    const apiUtil = new ApiUtil(setSpinnerOpen);

    const handleMsrTypeChange = (event) => {
        setMsrType(event.target.value);
    };

    const handleMsrDateChange = (date) => {
        setMsrDate(date);
    };

    const handleFromDateChange = (date) => {
        const fromDate = new moment(date).format('YYYY-MM-DD').toString();
        setFromDate(fromDate);
        setCond({...cond, fromDate: fromDate});
    };

    const handleToDateChange = (date) => {
        const toDate = new moment(date).format('YYYY-MM-DD').toString();
        setToDate(toDate);
        setCond({...cond, toDate: toDate});
    };

    const handleMsrValueChange = (e)=> {
        setMsrValue(e.target.value);
    };

    const handleMsrMemoChange = (e)=> {
        setMsrMemo(e.target.value);
    };

    const handleClose = (event, reason) => {

        if (reason === 'clickaway') return;

        setOpen(false);
    };

    const handleConfirmClose = (newValue) => {
        setConfirmOpen(false);

        if (newValue === 'Y') {

            // 삭제 요청
            apiUtil.deleteBSMData(delData).then(response=> {
                fetch();
            }).catch(error=> {
                setErrMessage('삭제 오류 : ' + error);
                setOpen(true);
            });
        }

    };

    const editData = (bsmInfo) => {
        setMsrDate(new Date(bsmInfo.msrDate));
        setMsrType(bsmInfo.msrType);
        setMsrValue(bsmInfo.msrVal);
        if (bsmInfo.msrMemo===undefined)
            setMsrMemo('');
        else
            setMsrMemo(bsmInfo.msrMemo);
        window.scrollTo(0, 0);
    }

    const deleteData = (item) => {

        setDelData(item);
        setConfirmOpen(true);

    }

    useEffect(() => {

        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetch = ()=> {

        apiUtil.getBSMList(cond).then(response=>{
            setBsmData(response.data);
        }).catch(error=>{
            setErrMessage('조회 오류 : ' + error);
            setOpen(true);
        });
    }

    const save = ()=> {

        if ( msrValue==='' || msrValue.length < 2 || msrValue.length > 3) {
            setErrMessage('혈당 측정 결과를 정확히 입력해주세요');
            setOpen(true);
        } else {

            inputData.msrDate = moment(msrDate).format('YYYY-MM-DD');
            inputData.msrType = msrType;
            inputData.msrVal = msrValue;
            inputData.msrMemo = msrMemo;

            apiUtil.saveBSM(inputData).then(response=>{
                setMsrDate(new Date());
                setMsrValue('');
                setMsrMemo('');
                fetch();
            }).catch(error=> {
                setErrMessage('저장 오류 : ' + error);
                setOpen(true);
            });
        }
    }

    return (
        <div>
            <Paper style={{
                marginTop: '20px',
                padding: '10px',
                paddingLeft: '20px',
                paddingRight: '20px',
                textAlign: 'center'
            }}>
                <Typography variant="h6" component="h6">
                    혈당 측정 결과 입력
                </Typography>
                <MuiPickersUtilsProvider locale={ko} utils={DateFnsUtils}>
                    <Grid container spacing={1} alignContent={"space-around"} alignItems={"stretch"}
                          justifyContent={"center"}>
                        <Grid item xs={12}>
                            <KeyboardDatePicker
                                margin="normal"
                                id="date-picker-dialog"
                                label="측정일"
                                format="yyyy년 MM월 dd일"
                                value={msrDate}
                                onChange={handleMsrDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                style={{textAlign: 'center'}}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Select
                                labelId="msr-type-select-helper-label"
                                id="msr-type-select-helper"
                                value={msrType}
                                onChange={handleMsrTypeChange}
                                variant="outlined"
                                style={{width: '100%'}}
                            >
                                <MenuItem value="00">조식전</MenuItem>
                                <MenuItem value="01">조식후</MenuItem>
                                <MenuItem value="10">중식전</MenuItem>
                                <MenuItem value="11">중식후</MenuItem>
                                <MenuItem value="20">석식전</MenuItem>
                                <MenuItem value="21">석식후</MenuItem>
                                <MenuItem value="30">취침전</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={6} style={{paddingLeft: '10px'}}>
                            <TextField
                                id="bsm-number"
                                label="혈당수치"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">mg/dL</InputAdornment>,
                                }}
                                variant="outlined"
                                value={msrValue}
                                onChange={handleMsrValueChange}
                                style={{width: '100%'}}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField id="bsm-memo" label="메모 (식단 / 운동 / 특이사항)" style={{width: '100%'}}
                                       value={msrMemo} InputLabelProps={{
                                shrink: true,
                            }}
                                       multiline
                                       onChange={handleMsrMemoChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Button color={"primary"} variant="contained" style={{width: '100%'}} onClick={save}>
                                저장
                            </Button>
                        </Grid>
                    </Grid>
                </MuiPickersUtilsProvider>
            </Paper>
            <Grid container style={{ marginTop: '30px' }}>
                <Grid item xs={5}  style={{ paddingLeft: '5px', paddingRight: '5px'}}>
                    <MuiPickersUtilsProvider locale={ko} utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-from-date"
                            label="조회 시작일"
                            format="yyyy-MM-dd"
                            value={fromDate}
                            onChange={handleFromDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            style={{textAlign: 'center'}}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={5} style={{ paddingLeft: '5px', paddingRight: '5px'}}>
                    <MuiPickersUtilsProvider locale={ko} utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-to-date"
                            label="조회 종료일"
                            format="yyyy-MM-dd"
                            value={toDate}
                            onChange={handleToDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            style={{textAlign: 'center'}}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={2} style={{ paddingTop: '25px' }}>
                    <Button style={{ backgroundColor: blue[300], width: '100%' }} variant="contained" onClick={fetch}>조회</Button>
                </Grid>
            </Grid>
            <BSMList bsmData={bsmData} editData={editData} deleteData={deleteData}/>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert onClose={handleClose} severity="warning">
                    {errMessage}
                </Alert>
            </Snackbar>
            <ConfirmationDialogRaw
                id="delete-confirm"
                keepMounted
                open={confirmOpen}
                onClose={handleConfirmClose}
                value={'N'}
            />
            <ModalSpinner spinnerOpen={spinnerOpen}/>
        </div>
    );
}
