import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import APIUtil from "../../api/ApiUtil";
import {CircularProgress, Divider, Modal} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import {Route, Switch, useHistory, useRouteMatch} from "react-router-dom";
import BloodSugar from "../blood_sugar/BloodSugar";
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import WeightPressure from "../weight_pressure/WeightPressure";
import Stats from "../stats/Stats";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100%)'
    },
    footer: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        marginTop: 'auto',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    },
    main: {
        overflowY : 'auto',
        minHeight: 'calc(100% - 80px)',
        paddingBottom: '80px'
    },
    bottomNav: {
        width: '100%',
        height: '80px',
    },
    divider: {
        backgroundColor: '#EFEFEF'
    }
}));

export default function Main() {

    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [isFetching, setIsFetching] = React.useState(false);

    const apiUtil = new APIUtil(setIsFetching);

    let matchBSM = useRouteMatch({path:"/", exact: true});
    let matchHB1AC = useRouteMatch("/hb1ac");
    let matchSTATS = useRouteMatch("/stats");
    let matchHOSPITAL = useRouteMatch("/hospital");
    let matchMORE = useRouteMatch("/more");

    useEffect(() => {

        apiUtil.checkLogin().then(response=> { });

        if (matchBSM) {
            setValue(0);
        }else if (matchHB1AC) {
            setValue(2);
        }else if (matchSTATS) {
            setValue(6);
        }else if (matchHOSPITAL) {
            setValue(3);
        }else if (matchMORE) {
            setValue(8);
        }


    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    let history = useHistory();

    return (
        <>
            <div className={classes.root}>
                <Container component="main" className={classes.main} maxWidth="sm">
                    <Switch>
                        <Route exact path="/">
                            <BloodSugar/>
                        </Route>
                        <Route path="/hb1ac">
                            혈액 검사 결과 입력
                        </Route>
                        <Route path="/stats">
                            <Stats />
                        </Route>
                        <Route path="/hospital">
                            병원 검진 일정
                        </Route>
                        <Route path="/wnp">
                            <WeightPressure/>
                        </Route>
                        <Route path="/more">
                            더보기
                        </Route>
                    </Switch>
                </Container>
                <footer className={classes.footer}>
                    <Divider/>
                    <BottomNavigation
                        value={value}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                        }}
                        showLabels
                        className={classes.bottomNav}
                    >
                        <BottomNavigationAction label="혈당 측정" icon={<AddCircleIcon/>} onClick={()=> { history.push("/") }}/>
                        <Divider orientation="vertical" flexItem className={classes.divider}/>
                        <BottomNavigationAction label="체중 혈압" icon={<FitnessCenterIcon/>} onClick={()=> { history.push("/wnp") }}/>
                        <Divider orientation="vertical" flexItem className={classes.divider}/>
                        <BottomNavigationAction label="혈액 검사" icon={<LocalHospitalIcon/>} onClick={()=> { history.push("/hb1ac") }}/>
                        <Divider orientation="vertical" flexItem className={classes.divider}/>
                        <BottomNavigationAction label="통계" icon={<InsertChartIcon/>} onClick={()=> { history.push("/stats") }}/>
                        <Divider orientation="vertical" flexItem className={classes.divider}/>
                        <BottomNavigationAction label="더보기" icon={<MoreHorizIcon/>} onClick={()=> { history.push("/more") }}/>
                    </BottomNavigation>
                </footer>
            </div>
            <Modal open={isFetching} disableEnforceFocus={true} disableAutoFocus={true}
                   style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <div>
                    <CircularProgress color="secondary"/>
                </div>
            </Modal>
        </>
    );
};