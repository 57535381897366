import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import SignIn from "./SignIn";
import Main from "./component/main/Main";
import {createTheme, ThemeProvider} from '@material-ui/core/styles';
import {pink, purple} from '@material-ui/core/colors';


const theme = createTheme({
    palette: {
        primary: {
            main: pink[600],
        },
        secondary: {
            main: purple[500],
        },
    },
    typography: {
        fontFamily: [
            'Gowun Dodum',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif'
        ].join(','),
    }
});

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <Router>
            <Switch>
                <Route path={"/login"}>
                    <SignIn/>
                </Route>
                <Route path={"/"}>
                    <Main/>
                </Route>
            </Switch>
        </Router>
    </ThemeProvider>
    ,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
