import React, {useEffect} from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputAdornment,
    Paper,
    Snackbar,
    TextField
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import ko from "date-fns/locale/ko";
import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {blue} from "@material-ui/core/colors";
import ModalSpinner from "../common/ModalSpinner";
import moment from "moment";
import MuiAlert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";
import ApiUtil from "../../api/ApiUtil";
import WnPList from "./WnPList";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


function ConfirmationDialogRaw(props) {
    const { onClose, value: valueProp, open, ...other } = props;

    const handleCancel = () => {
        onClose('N');
    };

    const handleOk = () => {
        onClose('Y');
    };

    return (
        <Dialog
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={open}
            {...other}
        >
            <DialogTitle id="confirmation-dialog-title">삭제 확인</DialogTitle>
            <DialogContent dividers>
                <Typography>
                    측정 기록을 삭제할까요?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel} color="primary">
                    아니오
                </Button>
                <Button onClick={handleOk} color="primary">
                    예
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ConfirmationDialogRaw.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
};

/**
 * 체중, 혈압 관리
 *
 * @constructor
 */
export default function WeightPressure() {

    let inputData = {};
    const [msrDate, setMsrDate] = React.useState(new moment(new Date()).format('YYYY-MM-DD').toString());
    const [wgtVal, setWgtVal] = React.useState('');
    const [prsHigh, setPrsHigh] = React.useState('');
    const [prsLow, setPrsLow] = React.useState('');
    const [spinnerOpen, setSpinnerOpen] = React.useState(false);
    const [wnpData, setWnpData] = React.useState({});
    const [delData, setDelData] = React.useState({});
    const [errMessage, setErrMessage] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [confirmOpen, setConfirmOpen] = React.useState(false);

    // 최초 조회시 기본 값 Load
    const [cond, setCond] = React.useState({
        "page": 0,
        "size": 9999,
        "fromDate": new moment(new Date()).subtract(3, 'M').format('YYYY-MM-DD').toString(),
        "toDate": new moment(new Date()).format('YYYY-MM-DD').toString(),
        "sortOrder": "desc"
    });

    const [fromDate, setFromDate] = React.useState(new moment(new Date()).subtract(3, 'M').format('YYYY-MM-DD').toString());
    const [toDate, setToDate] = React.useState(new moment(new Date()).format('YYYY-MM-DD').toString());

    const apiUtil = new ApiUtil(setSpinnerOpen);

    const handleMsrDateChange = (date) => {

        setMsrDate(new moment(date).format('YYYY-MM-DD').toString());
    }

    const handleWgtValChange = (e) => {

        setWgtVal(e.target.value);
    }

    const handlePrsHighChange = (e) => {
        setPrsHigh(e.target.value);
    }

    const handlePrsLowChange = (e) => {
        setPrsLow(e.target.value);
    }

    const handleFromDateChange = (date) => {
        const fromDate = new moment(date).format('YYYY-MM-DD').toString();
        setFromDate(fromDate);
        setCond({...cond, fromDate: fromDate});
    };

    const handleToDateChange = (date) => {
        const toDate = new moment(date).format('YYYY-MM-DD').toString();
        setToDate(toDate);
        setCond({...cond, toDate: toDate});
    };

    useEffect(() => {

        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetch = ()=> {

        apiUtil.getWnPList(cond).then(response=>{
            setWnpData(response.data);
        }).catch(error=>{
            setErrMessage('조회 오류 : ' + error);
            setOpen(true);
        });
    }

    const deleteData = (item) => {

        setDelData(item);
        setConfirmOpen(true);

    }

    const editData = (wnpInfo) => {
        setMsrDate(new Date(wnpInfo.msrDate));
        setWgtVal(wnpInfo.wgtVal);
        setPrsHigh(wnpInfo.prsHigh);
        setPrsLow(wnpInfo.prsLow);
        window.scrollTo(0, 0);
    }

    const handleClose = (event, reason) => {

        if (reason === 'clickaway') return;

        setOpen(false);
    };

    const handleConfirmClose = (newValue) => {

        setConfirmOpen(false);

        if (newValue === 'Y') {

            // 삭제 요청
            apiUtil.deleteWnPData(delData).then(response=> {
                fetch();
            }).catch(error=> {
                setErrMessage('삭제 오류 : ' + error);
                setOpen(true);
            });
        }

    };



    const save = ()=> {

        inputData.msrDate = moment(msrDate).format('YYYY-MM-DD').toString();
        inputData.wgtVal = wgtVal;
        inputData.prsHigh = prsHigh;
        inputData.prsLow = prsLow;

        apiUtil.saveWnP(inputData).then(response=>{
            setMsrDate(new Date());
            setWgtVal('');
            setPrsHigh('');
            setPrsLow('');
            fetch();
        }).catch(error=> {
            setErrMessage('저장 오류 : ' + error);
            setOpen(true);
        });
    }

    return (
        <div>
            <Paper style={{
                marginTop: '20px',
                padding: '10px',
                paddingLeft: '20px',
                paddingRight: '20px',
                textAlign: 'center'
            }}>
                <Typography variant="h6" component="h6">
                    체중 / 혈압 입력
                </Typography>
                <MuiPickersUtilsProvider locale={ko} utils={DateFnsUtils}>
                    <Grid container spacing={1} alignContent={"space-around"} alignItems={"stretch"}
                          justifyContent={"center"}>
                        <Grid item xs={12}>
                            <KeyboardDatePicker
                                margin="normal"
                                id="date-picker-dialog"
                                label="측정일"
                                format="yyyy년 MM월 dd일"
                                value={msrDate}
                                onChange={handleMsrDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                style={{textAlign: 'center'}}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="weight-number"
                                label="몸무게"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                                }}
                                variant="outlined"
                                value={wgtVal}
                                onChange={handleWgtValChange}
                                style={{width: '100%'}}
                            />
                        </Grid>
                        <Grid item xs={6} style={{paddingRight: '10px'}}>
                            <TextField
                                id="press1-number"
                                label="혈압(수축기)"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                value={prsHigh}
                                onChange={handlePrsHighChange}
                                style={{width: '100%'}}
                            />
                        </Grid>
                        <Grid item xs={6} style={{paddingLeft: '10px'}}>
                            <TextField
                                id="press2-number"
                                label="혈압(이완기)"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                value={prsLow}
                                onChange={handlePrsLowChange}
                                style={{width: '100%'}}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button color={"primary"} variant="contained" style={{width: '100%'}} onClick={save}>
                                저장
                            </Button>
                        </Grid>
                    </Grid>
                </MuiPickersUtilsProvider>
            </Paper>
            <Grid container style={{ marginTop: '30px' }}>
                <Grid item xs={5}  style={{ paddingLeft: '5px', paddingRight: '5px'}}>
                    <MuiPickersUtilsProvider locale={ko} utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-from-date"
                            label="조회 시작일"
                            format="yyyy-MM-dd"
                            value={fromDate}
                            onChange={handleFromDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            style={{textAlign: 'center'}}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={5} style={{ paddingLeft: '5px', paddingRight: '5px'}}>
                    <MuiPickersUtilsProvider locale={ko} utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-to-date"
                            label="조회 종료일"
                            format="yyyy-MM-dd"
                            value={toDate}
                            onChange={handleToDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            style={{textAlign: 'center'}}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={2} style={{ paddingTop: '25px' }}>
                    <Button style={{ backgroundColor: blue[300], width: '100%' }} variant="contained" onClick={fetch}>조회</Button>
                </Grid>
            </Grid>
            <WnPList wnpData={wnpData} editData={editData} deleteData={deleteData} />
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert onClose={handleClose} severity="warning">
                    {errMessage}
                </Alert>
            </Snackbar>
            <ConfirmationDialogRaw
                id="delete-confirm"
                keepMounted
                open={confirmOpen}
                onClose={handleConfirmClose}
                value={'N'}
            />
            <ModalSpinner spinnerOpen={spinnerOpen}/>
        </div>
    );
}