import React from 'react';
import SentimentVerySatisfiedRoundedIcon from '@material-ui/icons/SentimentVerySatisfiedRounded';
import SentimentDissatisfiedRoundedIcon from '@material-ui/icons/SentimentDissatisfiedRounded';
import SentimentVeryDissatisfiedRoundedIcon from '@material-ui/icons/SentimentVeryDissatisfiedRounded';
import {ListItemIcon} from "@material-ui/core";
import {green, orange, red} from "@material-ui/core/colors";

export default function BSMResultIcon(props) {

    const msrVal = props.msrVal;
    const msrType = props.msrType;

    let result = 0;

    // 공복/식전
    if (msrType === '00' || msrType === '10' || msrType === '20' || msrType === '30') {

        if (msrVal < 100)
            result = 0;
        else if (msrVal < 126)
            result = 1;
        else
            result = 2;

    } else {
        // 식후
        if (msrVal < 140)
            result = 0;
        else if (msrVal < 200)
            result = 1;
        else
            result = 2;
    }

    return (
        <ListItemIcon>
            {
                result === 0 ?
                    (<SentimentVerySatisfiedRoundedIcon style={{color: green[500]}}/>):(<></>)
            }
            {
                result === 1 ?
                    (<SentimentDissatisfiedRoundedIcon style={{color: orange[500]}}/>):(<></>)
            }
            {
                result === 2 ?
                    (<SentimentVeryDissatisfiedRoundedIcon style={{color: red[500]}}/>):(<></>)
            }
        </ListItemIcon>
    )
}