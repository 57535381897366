import React from 'react';
import List from "@material-ui/core/List";
import {Grid, ListSubheader} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import BSMListItem from "./BSMListItem";
import Typography from "@material-ui/core/Typography";
import moment from "moment";


const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '20px',
        width: '100%',
        // maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    }
}));

moment.locale('ko', {
    weekdays: ["일요일","월요일","화요일","수요일","목요일","금요일","토요일"],
    weekdaysShort: ["일","월","화","수","목","금","토"],
});


export default function BSMList(props) {

    const classes = useStyles();
    const bsmData = props.bsmData;

    let prevDate = '';

    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                    혈당 측정 이력
                </ListSubheader>
            }
            className={classes.root}
        >
            {
                bsmData && bsmData.content && bsmData.content.map((item, idx) => {

                    let isDateChanged = item.msrDate !== prevDate;
                    let dateDiv;
                    let wgt = '-- kg';
                    if (item.weightNPressure && item.weightNPressure.wgtVal > 0) wgt = item.weightNPressure.wgtVal + ' kg';
                    prevDate = item.msrDate;

                    if (isDateChanged) {
                        dateDiv = (
                            <>
                                <hr style={{marginTop: '10px', marginBottom: '20px'}}/>
                                <Grid container>
                                    <Grid item xs={8}>
                                        <Typography variant="h6" align="left">
                                            {moment(prevDate).format('YYYY년 MM월 DD일 (ddd)')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="h6" align="right">
                                            {wgt}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </>);
                    }else {
                        dateDiv = '';
                    }

                    return (
                        <div key={idx}>
                            {dateDiv}
                            <BSMListItem item={item} key={item.id} editData={props.editData}
                                         deleteData={props.deleteData}/>
                        </div>
                    );
                })}
        </List>
    );
}