import {CircularProgress, Modal} from "@material-ui/core";
import React from "react";


export default function ModalSpinner(props) {

    return (
        <Modal open={props.spinnerOpen} disableEnforceFocus={true} disableAutoFocus={true}
               style={{display:'flex',alignItems:'center',justifyContent:'center'}} >
            <div>
                <CircularProgress color="secondary" />
            </div>
        </Modal>
    )
}